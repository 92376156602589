main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

footer {
  margin-top: 40px;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
  opacity: 0.7;
}
