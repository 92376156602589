.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  box-shadow: none;
  margin-bottom: 20px;
}

.topbar__title {
  letter-spacing: 3px;
  margin: 0 12px;
}
