.homepage {
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.homepage__clock {
  margin: 36px 0;
}

#homepage__demo {
  margin-top: 40px;
  max-width: 600px;
  width: 100%;
}

.homepage__btn {
  margin: 5px;
}

.homepage__title {
  font-size: 24px;
  margin-top: 30px;
  text-transform: uppercase;
}

.homepage__title2 {
  margin-top: 16px;
  font-size: 18px;
}

.homepage__title:after {
  content: "";
  float: left;
  /* background: #f6757a; */
  background-color: #212121;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 40px;
}

.homepage__body {
  font-size: 16px;
}

.homepage__body2 {
  font-size: 14px;
}

@media screen and (min-width: 400px) {
  .homepage__title {
    font-size: 27px;
  }
  .homepage__title2 {
    font-size: 20px;
  }
  .homepage__body {
    font-size: 18px;
  }
  .homepage__body2 {
    font-size: 16px;
  }
}

@media screen and (min-width: 720px) {
  .homepage__title {
    font-size: 30px;
  }
  .homepage__title2 {
    font-size: 22px;
  }
  .homepage__body {
    font-size: 20px;
  }
  .homepage__body2 {
    font-size: 18px;
  }
}
