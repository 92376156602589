body {
  margin: 0;
  font-family: "Baloo Chettan 2", "Helvetica Neue", "Dejavu Sans", sans-serif;
  color: #212121;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
